<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4 class="d-inline-block"><i class="nav-icon icon-layers"></i> Invoices </h4><router-link class="btn btn-success float-right" :to="{ name: 'Vendor Create Invoice'}"><i class="fa fa-plus"></i> Create Invoice</router-link>
          <hr>
          <div class="row my-3 mb-1">
            <div class="col-md-4 mb-3">
              <h5>Select Company</h5>
              <v-select
                class="project-select"
                v-model="companyid"
                label="cn"
                :options="companyData"
                :value="companyid"
                placeholder="Search For Company"
                @change="getProjects()"
              ></v-select>
            </div>
            <div class="col-9 col-md-4" v-if="companyid">
              <h5 v-if="companyid">Select Project</h5>
              <v-select
                class="project-select"
                v-model="projectid"
                label="projectname"
                :options="projectData"
                :value="projectid"
                placeholder="Search For Project"
                @input="loadInvoices()"
              ></v-select>
            </div>
            <div v-if="projectid" class="col-3 col-md-4">
              <h5>&nbsp;</h5>
              <button @click="loadInvoices()" class="btn btn-primary"><i class="fa fa-sync-alt"></i></button>
            </div>
          </div>
          <v-client-table class="vendor-table inv-status-table table-responsive" ref="invoiceTable" :columns="columns" :data="invoiceData" :options="options" :theme="theme" id="dataTable">
            <span slot="total" slot-scope="props">
             {{formatPrice(props.row.total)}}
            </span>

            <div slot="quickpay_total" slot-scope="props">
              <span v-if="props.row.quickpay_total > 0 && $moment(props.row.endtday) > $moment()">

              {{formatPrice(props.row.quickpay_total)}}

              </span>
            </div>
            <span slot="creation_date" slot-scope="props">{{props.row.creation_date | moment('MM-DD-YYYY hh:mm A')}}</span>

            <span slot="invnum" slot-scope="props">
              <a href="javascript:void(0)" @click="setTheInvoice(props, 0, 0)" v-if="props.row.inv_passed === 2 || props.row.inv_passed === 5 || props.row.inv_passed === 14 || props.row.inv_passed === 24 || props.row.inv_passed === 34 || props.row.inv_passed === 44 || props.row.inv_passed === 54 || props.row.inv_passed === 17">{{props.row.invnum}} <i class="fa fa-edit"></i></a>

              <a href="javascript:void(0)" @click="setTheInvoice(props, 0, 1, true)" v-else-if="$session.get('user') == 'accountant' && props.row.inv_passed == 8">{{props.row.invnum}}</i></a>

              <a href="javascript:void(0)" @click="setTheInvoice(props.row)" v-else>{{props.row.invnum}}</a>



              <p class="mb-0 d-sm-block d-md-none">{{props.row.creation_date | moment('MM-DD-YYYY')}}</p>
            </span>

            <div slot="literalStatus" slot-scope="props">
              <div v-if="props.row.accountant_paid === 0">
                <div v-if="props.row.inv_passed === 9">
                  <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By WSS (Submit Again)</button>
                </div>

                <div v-if="props.row.inv_passed === 17">
                  <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Accountant</button>
                </div>

                <div v-if="props.row.inv_passed === 8 && props.row.acc_project != 1">
                  <button class="btn-sm btn-warning btn-pm"><i class="fa fa-question"></i> Pending By Project Manager</button>
                </div>

                <div v-if="props.row.inv_passed === 8 && props.row.acc_project == 1">
                  <button class="btn-sm btn-warning btn-pm" style="background: #24cabf !important;"><i class="fa fa-question"></i> Pending For First Approval</button>
                </div>

                <div v-if="props.row.inv_passed === 7 ">
                  <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 6 ">
                  <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
                </div>

                <div v-if="props.row.inv_passed === 5 ">
                  <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Project Manager (Pending Vendor)</button>
                </div>

                <div v-if="props.row.inv_passed === 0 ">
                  <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By WSS</button>
                </div>

                <div v-if="props.row.inv_passed === 2 ">
                  <router-link class="w-100 text-center d-block btn-sm btn-blue"  :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-save"></i> Draft</router-link>
                </div>

                <div v-if="props.row.inv_passed === 11">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 13">
                 <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
               </div>

                <div v-if="props.row.inv_passed === 14">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger" :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>

                <div v-if="props.row.inv_passed === 21">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 23">
                 <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
                </div>

                <div v-if="props.row.inv_passed === 24">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger" :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>

                <div v-if="props.row.inv_passed === 31">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 33">
                 <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
                </div>

                <div v-if="props.row.inv_passed === 34">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger" :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>

                <div v-if="props.row.inv_passed === 41">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 43">
                 <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
                </div>

                <div v-if="props.row.inv_passed === 44">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger" :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>

                <div v-if="props.row.inv_passed === 51">
                 <button class="btn-sm btn-success"><i class="fa fa-clock"></i> Ready For Payment</button>
                </div>

                <div v-if="props.row.inv_passed === 54">
                 <router-link class="w-100 text-center d-block btn-sm btn-danger"  :to="{ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row }}"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</router-link>
                </div>
              </div>
              <div v-if="props.row.accountant_paid === 1">
                <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
              </div>
            </div>
            <div slot="history" slot-scope="props">
              <button v-b-modal.history @click="sendHistory(props.row)" class="btn-sm white btn-secondary"><i class="fa fa-history"></i></button>
            </div>

             <div slot="delete" slot-scope="props">
              <button class="btn-sm btn-danger" 
v-if="props.row.accountant_paid === 0 && props.row.inv_passed === 9 || props.row.inv_passed === 5 || props.row.inv_passed === 54 || props.row.inv_passed === 34 || 
props.row.inv_passed === 14 || props.row.inv_passed === 44 || props.row.inv_passed === 24 || props.row.inv_passed === 17"  
@click="deleteRejected_Invoice(props.row)"><i class="fa fa-trash"></i></button>
              <span v-else><b> - </b></span>
            </div>

          </v-client-table>
        </b-card>
        <b-modal ok-only id="history" ref="history" :title="'Invoice #' + history.invnum" title-tag="h2" centered size="xl">
          <InvoiceHistory v-bind:history="history"></InvoiceHistory>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import InvoiceHistory from './../invoice/InvoiceHistory'
import * as jwt_decode from 'jwt-decode';
Vue.use(ClientTable)

export default {
  name: 'Vendor',
  components: {
    ClientTable,
    InvoiceHistory,
  },
  data () {
    return {
      
      columns: ['literalStatus', 'invnum', 'ticketnum', 'vendor_invoice_number', 'creation_date', 'total', 'quickpay_total', 'history','delete'],
      invoiceData: [],
      companyData: [],
      history: [],
      type: {
        type: 'Unprocessed',
      },
      typeOptions: [
        {
          type: 'Processed',
        },
        {
          type: 'Unprocessed',
        }
      ],
      project: [],
      projectid: '',
      companyid: '',
      projectData: [],
      options: {
        initFilters:{
          'GENERIC': this.$session.get('query'),
        },
        orderBy: this.$session.get('orderBy'),
        initialPage: this.$session.get('page'),
        //filterByColumn: true,
        headings: {
          literalStatus: 'Status',
          invnum: 'Invoice #',
          creation_date: 'Submitted Date',
          total: 'Amount',
          quickpay_total: 'Quickpay',
          description: 'Description',
          well: 'Well License #',
          wss: 'WSS',
          wts: 'WTS',
          ticketnum: 'Field Ticket #',
          vendor_invoice_number: 'Vendor Invoice #'
        },
        sortable: ['quickpay_total', 'literalStatus', 'invnum', 'creation_date', 'total', 'description', 'well', 'wss', 'wts', ],
        filterable: ['literalStatus', 'invnum', 'ticketnum', 'vendor_invoice_number', 'creation_date', 'total', 'description', 'well', 'wss', 'wts', 'quickpay_total'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
    }
  },
  mounted: function () {
    this.$http.get('/vendor/get/allowed_companies')
    .then(response => {
      this.companyData = response.data.result;
       this.companyid = this.companyData[0];
      console.log(this.companyid,"cn miu-1");
    })

    if(this.$session.get('company') && this.$session.get('project')){
      this.companyid = this.$session.get('company');
      this.projectid = this.$session.get('project');
      console.log(this.companyid,"c mounted");
      this.$http.get('/vendor/get/profile')
      .then(response => {
        this.profile = response.data;
        this.loadInvoices();
      })
    } else {
      this.projectid = {projectid: 0, projectname: 'All'}
    }
  },
  methods: {
    deleteRejected_Invoice(row){

      let token = this.$session.get('jwt');
      var profile = jwt_decode(token);
      
      this.$session.set('company', this.companyid);
      this.$session.set('project', this.projectid);
       this.$http.delete('/vendor/get/invoices/' + this.companyid.companyid + "/" + row.projectid + '/' + profile.id + '/' + row.invoiceid).then(res=>{
           this.$toasted.show("Invoice Deleted successfully.", {
                type: "success",
                duration: "3000",
              });
          this.loadInvoices();    
       }).catch(err=>{
           this.$toasted.show("Invoice could not deleted.", {
                type: "error",
                duration: "3000",
              });
       });
    },
    setTheInvoice(props, setInvoice, rejectedNum, acc){
      this.setInvoice(props, setInvoice, this.$refs.invoiceTable);
      if(setInvoice == 1 && acc){
        this.$router.push({ name: 'Accountant PM Invoice', params: { invoice: props.row, set: setInvoice }})
      }
      if(setInvoice == 0){
        this.$router.push({ name: 'Vendor Edit Invoice', path: '/vendor/invoices/edit', params: { id: props.row.invoiceid, invoice: props.row, set: setInvoice, rejected: rejectedNum }})
      }
      else {
        this.$router.push({ name: 'Vendor Invoice', path: '/vendor/invoice', params: { invoice: props.row, set: 0 }});
      }
    },
    loadInvoices() {
      let token = this.$session.get('jwt');
      var profile = jwt_decode(token);
  
      this.$session.set('company', this.companyid);
      this.$session.set('project', this.projectid);
      this.$http.get('/vendor/get/invoices/' + this.companyid.companyid + "/" + this.projectid.projectid + '/' + profile.id)
      .then(response => {
        var temp = response.data.result;

        for (var i = 0; i < temp.length; i++) {
          if(temp[i].accountant_paid === 0){
            if(temp[i].inv_passed == 9){
              temp[i].literalStatus = "Rejected By WSS (Submit Again)";
            }
           if(temp[i].inv_passed == 17){
              temp[i].literalStatus = "Rejected By Accountant";
            }
            if(temp[i].inv_passed == 8 ){
              temp[i].literalStatus = "Pending By Project Manager";
            }

            if(temp[i].inv_passed == 7 ){
              temp[i].literalStatus = "Ready For Payment";
            }

            if(temp[i].inv_passed == 6 ){
              temp[i].literalStatus = "Pending By Higher Management";
            }

            if(temp[i].inv_passed == 5 ){
              temp[i].literalStatus = "Pending By Project Manager";
            }

            if(temp[i].inv_passed == 0 ){
              temp[i].literalStatus = "Pending By WSS";
            }

            if(temp[i].inv_passed == 2 ){
              temp[i].literalStatus = "Draft";
            }

            if(temp[i].inv_passed == 11){
              temp[i].literalStatus = "Ready For Payment";
            }

            if(temp[i].inv_passed == 13){
              temp[i].literalStatus = "Pending By Higher Management";
            }

            if(temp[i].inv_passed == 14){
              temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
            }

            if(temp[i].inv_passed == 21){
              temp[i].literalStatus = "Ready For Payment";
            }

            if(temp[i].inv_passed == 23){
              temp[i].literalStatus = "Pending By Higher Management";
            }

            if(temp[i].inv_passed == 24){
              temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
            }

            if(temp[i].inv_passed == 31){
              temp[i].literalStatus = "Ready For Payment";
            }

            if(temp[i].inv_passed == 33){
              temp[i].literalStatus = "Pending By Higher Management";
            }

            if(temp[i].inv_passed == 34){
              temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
            }

            if(temp[i].inv_passed == 41){
              temp[i].literalStatus = "Ready For Payment";
            }

            if(temp[i].inv_passed == 43){
              temp[i].literalStatus = "Pending By Higher Management";
            }

            if(temp[i].inv_passed == 44){
              temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
            }

            if(temp[i].inv_passed == 51){
              temp[i].literalStatus = "Ready For Payment";
            }

            if(temp[i].inv_passed == 54){
              temp[i].literalStatus = "Rejected By Management (Pending Vendor)";
            }
          }
          if(temp[i].accountant_paid === 1){
            temp[i].literalStatus = "Paid";
          }
        }
        this.invoiceData = temp.sort((n2,n1) => n1.creation_date - n2.creation_date).reverse();
        this.invoiceData.map((x,i)=>{

this.$http.get('/accountant/qbyte/invoices/inovoiceData/' + x.invoiceid).then(res=>{

  if(res.data.total !== undefined){

 this.invoiceData[i].total = res.data.total;
  this.invoiceData[i].quickpay_total= res.data.quickpay_total;
  
  }

 }).catch(err=>{

  console.log(err);

})

});

      });

    },
    getProjects(){
      this.$http.get('/vendor/get/allowed_projects/' + this.companyid.companyid + '/' + '?search=true')
      .then(response => {
        this.projectData = [{projectid: 0, projectname: 'All'}].concat(response.data.result);
        if(this.projectData.length > 0){
          if(!this.$session.get('project')){
            this.projectid = this.projectData[0];
            this.$session.set('project', this.projectData[0])
          }
          this.loadInvoices();
        }
        else {
          this.projectData = [];
          this.projectid = [];
        }
      })
    },
    sendHistory(history) {
      this.history = history;
    },
  },
}
</script>

<style lang="scss">
.vendor-table {
  overflow-x: hidden !important;
}
@media screen and (max-width: 767px){
  .vendor-table {
    th:nth-child(4),
    td:nth-child(4),
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7),
    th:nth-child(3),
    td:nth-child(3){
      display: none !important;
    }
    td, th{
      width: 50%;
    }
  }
}
@media screen and (max-width: 580px){
  .vendor-table {
    td, th{
      width: 50%;
    }
  }
}
</style>
